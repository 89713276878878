import React from "react";
import Language from "../Language";


export default function Russian({children, invert}) {
    return (
        <Language code={"ru"} invert={invert}>
            {children}
        </Language>
    )
}
