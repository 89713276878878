import Jumbox from "./components/Jumbox";
import Footer from "./components/Footer";
import React, {useState} from "react";
import LanguageGroup from "./components/i18n/LanguageGroup";
import Italian from "./components/i18n/lang/Italian";
import ContextLanguage from "./contexts/ContextLanguage";
import Box from "./components/Box"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
    faFacebookF,
    faInstagram,
    faPinterestP,
    faTelegramPlane,
    faTwitter,
    faWhatsapp
} from "@fortawesome/free-brands-svg-icons"
import {
    faAdjust,
    faCar, faCreditCard,
    faEnvelope,
    faPhoneVolume, faPlug,
    faSmileBeam, faSnowflake, faUsers,
    faVirusSlash, faWifi,
    faWind,
} from "@fortawesome/free-solid-svg-icons"
import QuoteForm from "./components/QuoteForm"

import Van11 from "./media/optimized/Van11Hidden.webp"
import Van13 from "./media/optimized/Van13.webp"
import Van14 from "./media/optimized/Van14.webp"
import Van15 from "./media/optimized/Van15.webp"
import Van18 from "./media/optimized/Van18.webp"
import Van23 from "./media/optimized/Van23.webp"
import Van27 from "./media/optimized/Van27.webp"
import Van29 from "./media/optimized/Van29.webp"
import Van30 from "./media/optimized/Van30.webp"
import Van31 from "./media/optimized/Van31.webp"
import Van32 from "./media/optimized/Van32.webp"
import CastelfrancoContrasto from "./media/optimized/CastelfrancoContrasto.webp"
import LogoSanNicola from "./media/optimized/LogoSanNicola.webp"
import LogoBper from "./media/LogoBper.svg"
import LogoCastello from "./media/optimized/LogoCastello.webp"
import IconContainer from "./components/IconContainer"
import Navbar from "./components/Navbar"
import Icon from "./components/Icon"
import ButtonsContainer from "./components/ButtonsContainer"
import Mosaic from "./components/Mosaic"
import Clean from "./components/Clean"
import Russian from "./components/i18n/lang/Russian"
import English from "./components/i18n/lang/English"


export default function App() {
    const [language, setLanguage] = useState(process.env.REACT_APP_LANGUAGE)

    return (
        <ContextLanguage.Provider value={language}>
            <div className={"dark"}>
            <Navbar setLanguage={setLanguage}/>
            <main>
                <Jumbox
                    id={"intro"}
                    backgroundImage={Van11}
                    backgroundPositionX={"25%"}
                    backgroundPositionY={"44%"}
                    height={"100vh"}
                    overlayPct={0.72}
                >
                    <Box>
                        <hgroup>
                            <h1>
                                <LanguageGroup>
                                    <Italian>Noleggio con conducente</Italian>
                                    <English>Rental with driver</English>
                                    <Russian>Аренда Автомобиля с водителем</Russian>
                                </LanguageGroup>
                            </h1>
                            <h1>
                                <LanguageGroup>
                                    <Italian>Autista privato</Italian>
                                    <English>Private driver</English>
                                    <Russian>Частный водитель с лицензией NCC</Russian>
                                </LanguageGroup>
                            </h1>
                        </hgroup>
                        <p>
                            <LanguageGroup>
                                <Italian>
                                     Siamo un’azienda che offre servizi di <b>noleggio auto con conducente</b> oppure di <b>autista privato</b> con sede a <b>Castelfranco Emilia</b>, luogo natale del Tortellino.
                                </Italian>
                                <English>
                                    We are a company that offers services of <b>car rental with driver</b> and <b>private driver</b> headquartered in <b>Castelfranco Emilia</b>, birthplace of Tortellini.
                                </English>
                                <Russian>
                                    Мы - компания, которая предлагает услуги по <b>аренде автомобилей Mercedes-Benz с водителем</b> (лицензия такси NCC). Находимся в Регионе <b>Эмилия Романья</b>, Модена, город <b>Кастельфранко-Эмилия</b>, на Родине знаменитых Tortellini.
                                </Russian>
                            </LanguageGroup>
                        </p>
                        <p>
                            <LanguageGroup>
                                <Italian>
                                    Possiamo raggiungere qualsiasi destinazione desideriate 24 ore su 24, 7 giorni su 7.
                                </Italian>
                                <English>
                                    We can take you to any destination you want 24/7.
                                </English>
                                <Russian>
                                    Мы встретим и доставим Вас до любого пункта назначения 24/7.
                                </Russian>
                            </LanguageGroup>
                        </p>
                        <ButtonsContainer>
                            <a href={"#quote"} className={"button fixed"}>
                                <FontAwesomeIcon icon={faEnvelope}/>
                                &nbsp;
                                <LanguageGroup>
                                    <Italian>Preventivo gratuito</Italian>
                                    <English>Free quote</English>
                                    <Russian>Бесплатная оценка</Russian>
                                </LanguageGroup>
                            </a>
                            <LanguageGroup>
                                <Italian>
                                    <a href={"https://wa.me/393515086558"} className={"button fixed"}>
                                        <FontAwesomeIcon icon={faWhatsapp}/>
                                        &nbsp;
                                        Whatsapp
                                    </a>
                                </Italian>
                                <English>
                                    <a href={"https://wa.me/393515086558"} className={"button fixed"}>
                                        <FontAwesomeIcon icon={faWhatsapp}/>
                                        &nbsp;
                                        Whatsapp
                                    </a>
                                </English>
                                <Russian>
                                    <a href={"https://wa.me/393335853205"} className={"button fixed"}>
                                        <FontAwesomeIcon icon={faWhatsapp}/>
                                        &nbsp;
                                        Whatsapp
                                    </a>
                                </Russian>
                            </LanguageGroup>
                        </ButtonsContainer>
                        <ButtonsContainer style={{"--icon-size": "36px"}}>
                            <div>
                                <LanguageGroup>
                                    <Italian>
                                        <a href={"tel:+393515086558"} target={"_blank"} rel={"noopener"} aria-label={"Whatsapp"}>
                                            <Icon>
                                                <FontAwesomeIcon icon={faPhoneVolume}/>
                                            </Icon>
                                        </a>
                                    </Italian>
                                    <English>
                                        <a href={"tel:+393515086558"} target={"_blank"} rel={"noopener"} aria-label={"Whatsapp"}>
                                            <Icon>
                                                <FontAwesomeIcon icon={faPhoneVolume}/>
                                            </Icon>
                                        </a>
                                    </English>
                                    <Russian>
                                        <a href={"tel:+393335853205"} target={"_blank"} rel={"noopener"} aria-label={"Whatsapp"}>
                                            <Icon>
                                                <FontAwesomeIcon icon={faPhoneVolume}/>
                                            </Icon>
                                        </a>
                                    </Russian>
                                </LanguageGroup>
                            </div>
                            <div>
                                <a href={"https://t.me/abtaxincc"} target={"_blank"} rel={"noopener"} aria-label={"Telegram"}>
                                    <Icon>
                                        <FontAwesomeIcon icon={faTelegramPlane}/>
                                    </Icon>
                                </a>
                            </div>
                            <div>
                                <a href={"https://instagram.com/ab_taxi_ncc"} target={"_blank"} rel={"noopener"} aria-label={"Instagram"}>
                                    <Icon>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </Icon>
                                </a>
                            </div>
                            <div>
                                <a href={"https://www.facebook.com/AB-TAXI-NCC-104689525027932/"} target={"_blank"} rel={"noopener"} aria-label={"Facebook"}>
                                    <Icon>
                                        <FontAwesomeIcon icon={faFacebookF}/>
                                    </Icon>
                                </a>
                            </div>
                        </ButtonsContainer>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"offer"}
                    height={"70vh"}
                    backgroundImage={Van14}
                    overlayPct={0.72}
                >
                    <Box>
                        <h1>
                            <LanguageGroup>
                                <Italian>La nostra offerta</Italian>
                                <English>Our offer</English>
                                <Russian>Кому подойдут наши услуги и предложения</Russian>
                            </LanguageGroup>
                        </h1>
                        <LanguageGroup>
                            <Italian>
                                <p>
                                    Offriamo soluzioni di trasporto sia ai <b>privati</b> che alle <b>aziende</b>, dai turisti alla ricerca della comodità durante la loro vacanza agli uomini d’affari in cerca di puntualità e di un trattamento serio e professionale di prima classe.
                                </p>
                                <p>
                                    Inoltre, ci rivolgiamo alle <b>strutture alberghiere</b>, che potranno affidarsi a noi per soddisfare le richieste, anche <b>last minute</b>, dei propri ospiti.
                                </p>
                            </Italian>
                            <English>
                                <p>
                                    We offer transfer solution to both <b>private individuals</b> and <b>companies</b>, from tourists looking for comfort during their vacation to businessmen seeking timeliness and professionality in their travels.
                                </p>
                                <p>
                                    We also are available for <b>accommodation facilities</b>, which can partner with us to satisfy their clients' <b>last minute</b> requests.
                                </p>
                            </English>
                            <Russian>
                                <ul>
                                    <li><b>Частные лица</b></li>
                                    <li><b>Компании, Бизнес партнёры</b></li>
                                    <li><b>Туристы</b></li>
                                </ul>
                                <p>
                                    Для всех, кто ищет комфорт во время отпуска или во время деловой поездки по Италии.
                                </p>
                                <p>
                                    Для всех, кто ищет пунктуальность и серьезное и профессиональное обслуживание на высшем уровне.
                                </p>
                                <p>
                                    Кроме того, мы обращаемся к <b>гостиничным службам</b>, которые могут рассчитывать на нас, чтобы удовлетворить запросы своих гостей, даже <b>в последнюю минуту</b>.
                                </p>
                            </Russian>
                        </LanguageGroup>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"extralong"}
                    height={"70vh"}
                    backgroundImage={Van23}
                    overlayPct={0.90}
                >
                    <Box>
                        <h1>
                            Mercedes-Benz V Class Extralong
                        </h1>
                        <p>
                            <LanguageGroup>
                                <Italian>Se siete alla ricerca di un veicolo fuori dal comune, questo è la scelta giusta.</Italian>
                                <English>If you're looking for an extraordinary vehicle, this is the right choice.</English>
                                <Russian>Если вы ищете комфортный, удобный и качественный  автомобиль, это правильный выбор.</Russian>
                            </LanguageGroup>
                        </p>
                        <Mosaic
                            elements={[
                                {
                                    href: Van29,
                                    src: Van29,
                                    label: "Image 1"
                                },
                                {
                                    href: Van30,
                                    src: Van30,
                                    label: "Image 2"
                                },
                                {
                                    href: Van31,
                                    src: Van31,
                                    label: "Image 3"
                                },
                                {
                                    href: Van32,
                                    src: Van32,
                                    label: "Image 4"
                                },
                            ]}
                            size={"160px"}
                        />
                        <IconContainer icon={<FontAwesomeIcon icon={faUsers}/>} size={"large"}>
                            <p>
                                <LanguageGroup>
                                    <Italian>Ideale per trasferimenti di gruppo, è in grado di ospitare fino a <b>7 persone</b>.</Italian>
                                    <English>Suited for group transfers, it’s able to host up to <b>7 people</b>.</English>
                                    <Russian>Идеально подходит для групповых и семейных путешествий.
                                        Пассажирские перевозки  до <b>7 человек</b>.</Russian>
                                </LanguageGroup>
                            </p>
                        </IconContainer>
                        <IconContainer icon={<FontAwesomeIcon icon={faCar}/>} size={"large"}>
                            <p>
                                <LanguageGroup>
                                    <Italian>La <b>cura degli interni</b>, in ogni dettaglio, è straordinaria, con l’eleganza e il calore dei rivestimenti in pelle.
                                    </Italian>
                                    <English>The <b>interior care</b> is outstanding in every its detail, with the elegance of its leather linings</English>
                                    <Russian><b>Комфорт</b> и <b>ухоженность</b> внутри салона, в каждой детали автомобиля, <b>с элегантностью</b> и теплотой кожаной обивки.</Russian>
                                </LanguageGroup>
                                </p>
                        </IconContainer>
                        <IconContainer icon={<FontAwesomeIcon icon={faSmileBeam}/>} size={"large"}>
                            <p>
                                <LanguageGroup>
                                    <Italian>
                                        Viaggiare a bordo di questa vettura significa vivere un’esperienza gratificante, per tutti i sensi e in tutti i sensi, con il <b>massimo comfort</b>.
                                    </Italian>
                                    <English>
                                        Travelling in this car means living a rewarding experience, for all senses and in all senses, with the <b>maximum comfort</b>.
                                    </English>
                                    <Russian>
                                        Путешествие на этом автомобиле обеспечит вам <b>максимальный комфорт</b> во время поездки или путешествия.Добавит <b>незабываемые впечатления</b> и оставит только приятные воспоминания.
                                    </Russian>
                                </LanguageGroup>
                            </p>
                        </IconContainer>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"services"}
                    backgroundImage={Van27}
                    overlayPct={0.72}
                    height={"70vh"}
                >
                    <Box>
                        <h1>
                            <LanguageGroup>
                                <Italian>Servizi a bordo</Italian>
                                <English>Onboard services</English>
                                <Russian>Услуги на борту Mercedes-Benz</Russian>
                            </LanguageGroup>
                        </h1>
                        <p>
                            <LanguageGroup>
                                <Italian>
                                    A bordo dei nostri veicoli, troverete sempre:
                                </Italian>
                                <English>
                                    On board of our vehicles, you will always find:
                                </English>
                                <Russian>
                                    На борту наших автомобилей вы всегда найдете:
                                </Russian>
                            </LanguageGroup>
                        </p>
                        <IconContainer icon={<FontAwesomeIcon icon={faSnowflake}/>}>
                            <LanguageGroup>
                                <Italian><b>Climatizzatore</b> privato e regolabile</Italian>
                                <English>Private and adjustable <b>air conditioner</b></English>
                                <Russian>Частный регулируемый <b>кондиционер</b></Russian>
                            </LanguageGroup>
                        </IconContainer>
                        <IconContainer icon={<FontAwesomeIcon icon={faAdjust}/>}>
                            <LanguageGroup>
                                <Italian><b>Vetri oscurati</b> e <b>luci soffuse</b> per proteggere la vostra privacy</Italian>
                                <English><b>Privacy glass</b> and <b>soft lights</b> to protect your privacy</English>
                                <Russian><b>Тонированное стекло</b> и <b>мягкое освещение</b> для защиты вашей конфиденциальности</Russian>
                            </LanguageGroup>
                        </IconContainer>
                        <IconContainer icon={<FontAwesomeIcon icon={faPlug}/>}>
                            <LanguageGroup>
                                <Italian><b>Prese di corrente elettrica</b> e <b>USB</b> per ricaricare i vostri dispositivi</Italian>
                                <English><b>Electric</b> and <b>USB power outlets</b> to recharge your devices</English>
                                <Russian><b>Разъемы питания</b> и <b>USB</b> для зарядки ваших устройств</Russian>
                            </LanguageGroup>
                        </IconContainer>
                        <IconContainer icon={<FontAwesomeIcon icon={faWifi}/>}>
                            <LanguageGroup>
                                <Italian><b>Wi-fi gratuita</b> a vostra disposizione</Italian>
                                <English><b>Free Wi-fi</b> at your disposal</English>
                                <Russian><b>Бесплатный Wi-Fi</b> в вашем распоряжении</Russian>
                            </LanguageGroup>
                        </IconContainer>
                        <IconContainer icon={<FontAwesomeIcon icon={faCreditCard}/>} size={"large"}>
                            <LanguageGroup>
                                <Italian>Pagamenti in <b>contanti</b>, con <b>carta di credito</b> o <b>Google Pay</b> e <b>Apple Pay</b></Italian>
                                <English>Payments in <b>cash</b>, by <b>credit card</b>, or <b>Google Pay</b> and <b>Apple Pay</b></English>
                                <Russian><b>Оплата наличными</b>, <b>кредитной картой</b> или через <b>Google Pay</b> и <b>Apple Pay</b>.</Russian>
                            </LanguageGroup>
                        </IconContainer>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"clean"}
                    backgroundImage={Van15}
                    backgroundPositionY={"60%"}
                    height={"70vh"}
                    overlayPct={0.72}
                >
                    <Box>
                        <Clean>
                            <h1>
                                <LanguageGroup>
                                    <Italian>Sanificazione dell'auto</Italian>
                                    <English>Sanitization of the car</English>
                                    <Russian>Санитарная обработка автомобилей</Russian>
                                </LanguageGroup>
                            </h1>
                            <p>
                                <LanguageGroup>
                                    <Italian>Per noi non si tratta semplicemente di lavoro.</Italian>
                                    <English>This isn't just a simple job for us.</English>
                                    <Russian>Для нас это не просто работа.</Russian>
                                </LanguageGroup>
                            </p>
                            <IconContainer icon={<FontAwesomeIcon icon={faVirusSlash}/>} size={"large"}>
                                <p>
                                    <LanguageGroup>
                                        <Italian>
                                            Per questo motivo ogni giorno ci assicuriamo che l'auto possa iniziare i servizi nel rispetto delle normative vigenti anti-COVID-19 sanificando l'intero abitacolo con <b>ozononizzatore professionale</b>, prima e dopo ogni servizio.
                                        </Italian>
                                        <English>
                                            For this reason every day we make sure that the vehicle can start the services in compliance with the existing anti-COVID-19 rules by sanitizing before and after every service the entire passenger compartment with a <b>professional ozonizer</b>.
                                        </English>
                                        <Russian>
                                            По этой причине мы каждый день следим за тем, чтобы автомобиль мог запускать обслуживание в соответствии с действующими правилами по борьбе с COVID-19, дезинфицируя весь салон с помощью <b>профессионального ОЗОНАТОРА ВОЗДУХА</b>  до и после каждого обслуживания.
                                        </Russian>
                                    </LanguageGroup>
                                </p>
                            </IconContainer>
                            <IconContainer icon={<FontAwesomeIcon icon={faWind}/>} size={"large"}>
                                <p>
                                    <LanguageGroup>
                                        <Italian>
                                            E per respirare un'aria sana e pulita ogni tuo viaggio sarà accompagnato da uno <b>ionizzatore d'aria professionale</b>.
                                        </Italian>
                                        <English>
                                            And to breathe an healthy and clean air every trip will be played along with a <b>professional
                                            air ionizer</b>.
                                        </English>
                                        <Russian>
                                            А чтобы дышать чистым и здоровым воздухом, каждую поездку Вас будет сопровождать <b>профессиональный ИОНИЗАТОР воздуха</b>.
                                        </Russian>
                                    </LanguageGroup>
                                </p>
                            </IconContainer>
                            <p>
                                <LanguageGroup>
                                    <Italian>
                                        Solo così possiamo garantirti la massima igiene.
                                    </Italian>
                                    <English>
                                        Only in this way we can guarantee you the maximum hygiene.
                                    </English>
                                    <Russian>
                                        Только так мы можем гарантировать максимальную гигиену.
                                    </Russian>
                                </LanguageGroup>
                            </p>
                        </Clean>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"values"}
                    backgroundImage={Van18}
                    height={"70vh"}
                    overlayPct={0.72}
                >
                    <Box>
                        <h1>
                            <LanguageGroup>
                                <Italian>I nostri valori</Italian>
                                <English>Our beliefs</English>
                                <Russian>Наши ценности</Russian>
                            </LanguageGroup>
                        </h1>
                        <p>
                            <LanguageGroup>
                                <Italian>
                                    Ogni azienda ha la propria storia da raccontare e la nostra è stata tutto fuorché banale.
                                </Italian>
                                <English>
                                    Every company has its own tale to tell and ours is anything but trivial.
                                </English>
                                <Russian>
                                    У каждой компании есть своя история, и наша история далеко не банальна.
                                </Russian>
                            </LanguageGroup>
                        </p>
                        <p>
                            <LanguageGroup>
                                <Italian>
                                    Soprattutto agli inizi del nostro percorso abbiamo affrontato tanti ostacoli, tuttavia c'è sempre stata una costante: il desiderio di <b>perfezionare le nostre competenze</b> e di acquisire quelle abilità che avrebbero fatto diventare la nostra azienda sinonimo di <b>esperienza e professionalità</b> nel nostro campo.
                                </Italian>
                                <English>
                                    At the start of our path we faced many obstacles, but there's always been a constant: the desire to <b>improve and perfect our skills</b> and to acquire the skills that would make our company a synonim of <b>experience and professionality</b> in our field.
                                </English>
                                <Russian>
                                    Особенно в начале нашего пути мы столкнулись со многими препятствиями, однако всегда было одно постоянное- ЖЕЛАНИЕ  <b>СОВЕРШЕНСТВОВАТЬ свои НАВЫКИ</b> и приобретать те, которые сделали бы нашу компанию синонимом <b>опыта и профессионализма</b> в нашей области.
                                </Russian>
                            </LanguageGroup>
                        </p>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"partners"}
                    className={"light"}
                    backgroundImage={CastelfrancoContrasto}
                    height={"35vh"}
                    overlayPct={0.72}
                >
                    <Box>
                        <h1>
                            <LanguageGroup>
                                <Italian>I nostri partner</Italian>
                                <English>Our partners</English>
                                <Russian>Наши партнёры</Russian>
                            </LanguageGroup>
                        </h1>
                        <p/>
                        <Mosaic
                            size={"110px"}
                            elements={[
                                {
                                    src: LogoSanNicola,
                                    href: "https://www.lasannicola.it/",
                                    fit: "contain",
                                    isolate: true,
                                    caption: `Associazione di volontariato "La San Nicola"`,
                                },
                                {
                                    src: LogoCastello,
                                    href: "https://www.comune.castelfranco-emilia.mo.it/servizi/notizie/notizie_homepage.aspx",
                                    fit: "contain",
                                    isolate: true,
                                    caption: "Comune di Castelfranco Emilia"
                                },
                                {
                                    src: LogoBper,
                                    href: "https://www.bper.it",
                                    fit: "contain",
                                    isolate: true,
                                    caption: "BPER Banca"
                                },
                        ]}/>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"quote"}
                    backgroundImage={Van13}
                    backgroundPositionY={"top"}
                    overlayPct={0.90}
                >
                    <Box>
                        <h1>
                            <LanguageGroup>
                                <Italian>Richiedi un preventivo gratuito</Italian>
                                <English>Request a free quote</English>
                                <Russian>Запросить бесплатную оценка</Russian>
                            </LanguageGroup>
                        </h1>
                        <p>
                            <LanguageGroup>
                                <Italian>Compila questi campi per richiedere un <b>preventivo via email</b> selezionando privato o azienda:</Italian>
                                <English>Fill out these fields to request a <b>quote via email</b> by selecting private or company:</English>
                                <Russian>Заполните эти поля, чтобы запросить коммерческое предложение по электронной почте e-mail, выбрав “Частное лицо»  или «Компания»:</Russian>
                            </LanguageGroup>
                        </p>
                        <QuoteForm/>
                    </Box>
                </Jumbox>
                <Jumbox
                    id={"social"}
                    backgroundColor={"black"}
                    className={"invert"}
                    style={{
                        "paddingTop": "4px",
                        "paddingBottom": "4px",
                    }}
                    hlimiterProps={{
                        style: {
                            "gap": "12px",
                            "--icon-size": "calc(min(10vw, 48px))",
                        }
                    }}
                >
                </Jumbox>
                <Footer className={"invert"}>
                    © {new Date().getFullYear()} AB TAXI NCC - All Rights Reserved
                </Footer>
            </main>
        </div>
        </ContextLanguage.Provider>
    );
}
