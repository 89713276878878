import React, {useState, useContext, Fragment} from "react"
import Style from "./QuoteForm.module.css"
import classNames from "classnames"
import LanguageGroup from "./i18n/LanguageGroup"
import Italian from "./i18n/lang/Italian"
import ContextLanguage from "../contexts/ContextLanguage"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope} from "@fortawesome/free-solid-svg-icons"
import English from "./i18n/lang/English"
import Russian from "./i18n/lang/Russian"

const MAX_PEOPLE = 7;
const EMAIL = "info@abtaxi.it"
const LANGUAGES = {
    "it": "🇮🇹 Italiano",
    "en": "🇬🇧 English",
    "ru": "🇷🇺 Русский",
}


export default function QuoteForm({className}) {

    const language = useContext(ContextLanguage)

    const today = new Date();
    const minDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, "0")}-${today.getDate()}`

    const [type, setType] = useState("Privato")
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [tel, setTel] = useState("")
    const [departureDate, setDepartureDate] = useState("")
    const [departureTime, setDepartureTime] = useState("")
    const [arrivalDate, setArrivalDate] = useState("")
    const [arrivalTime, setArrivalTime] = useState("")
    const [departure, setDeparture] = useState("")
    const [arrival, setArrival] = useState("")
    const [adults, setAdults] = useState(1)
    const [children, setChildren] = useState(0)
    const [childrenAge, setChildrenAge] = useState("")
    const [chairs, setChairs] = useState(0)
    const [luggage, setLuggage] = useState("nessuna")
    const [twoways, setTwoways] = useState("false")
    const [notes, setNotes] = useState("")

    let mailSubject
    let mailContents

    if(type === "Privato") {
        mailSubject = `Preventivo - ${name} (Privato)`
        mailContents = `
            | Nome: 👤 ${name}
            | Numero di telefono: 📞 ${tel}
            | Lingua: ${LANGUAGES[language]}
            
            | Data: 🗓️ ${departureDate}
            | Ora: 🕒 ${departureTime}
            
            | Partenza: 📍 ${departure}
            | Arrivo: 📍 ${arrival}
            | Andata e ritorno: ${twoways ? '✅ Sì' : '❌ No'}
            ${twoways ? `
            | Data del ritorno: 🗓️ ${arrivalDate}
            | Ora del ritorno: 🕒 ${arrivalTime}
            `: ""}            
            | Adulti: 👥 ${adults}
            | Bambini: 👥 ${children}
            | Età bambini: 🎂 ${childrenAge}
            | Seggiolini: 🪑 ${chairs}
            | Valigie: 🧳 ${luggage}
            
            | ${notes}
        `
    }
    else if(type === "Azienda") {
        mailSubject = `Preventivo - ${company} (Azienda)`
        mailContents = `
            | Nome: 👤 ${name}
            | Azienda: 🏭 ${company}
            | Numero di telefono: 📞 ${tel}
            | Lingua: ${LANGUAGES[language]}
            
            | ${notes}
        `
    }

    const mailtoLink = encodeURI(`mailto:${EMAIL}?subject=${mailSubject}&body=${mailContents}`)

    return (
        <form className={classNames(Style.QuoteForm, className)}>
            <label className={Style.Label}>
                <div className={Style.Text}>
                    <LanguageGroup>
                        <Italian>Tipo preventivo</Italian>
                        <English>Type of quote</English>
                        <Russian>Вид заявки</Russian>
                    </LanguageGroup>
                </div>
                <div className={Style.Input}>
                    <select
                        value={type}
                        onChange={e => setType(e.target.value)}
                    >
                        <option value={"Privato"}>
                            {{
                                "it": "👤 Privato",
                                "en": "👤 Private",
                                "ru": "👤 Частное лицо",
                            }[language]}
                        </option>
                        <option value={"Azienda"}>
                            {{
                                "it": "🏭 Azienda",
                                "en": "🏭 Company",
                                "ru": "🏭 Компания",
                            }[language]}
                        </option>
                    </select>
                </div>
            </label>
            <label className={Style.Label}>
                <div className={Style.Text}>
                    <LanguageGroup>
                        <Italian>Nome e cognome</Italian>
                        <English>Name and surname</English>
                        <Russian>Имя и фамилия</Russian>
                    </LanguageGroup>
                </div>
                <div className={Style.Input}>
                    <input
                        type={"text"}
                        placeholder={"Mario Rossi"}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
            </label>
            {type === "Azienda" ?
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Azienda</Italian>
                            <English>Company</English>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"text"}
                            placeholder={"Ferrari, Lamborghini, ..."}
                            value={company}
                            onChange={e => setCompany(e.target.value)}
                        />
                    </div>
                </label>
            : null}
            <label className={Style.Label}>
                <div className={Style.Text}>
                    <LanguageGroup>
                        <Italian>Numero di telefono</Italian>
                        <English>Phone number</English>
                        <Russian>Номер телефона</Russian>
                    </LanguageGroup>
                </div>
                <div className={Style.Input}>
                    <input
                        type={"tel"}
                        placeholder={"+39 XXX XXX XXXX"}
                        value={tel}
                        onChange={e => setTel(e.target.value)}
                    />
                </div>
            </label>
            {type === "Privato" ? <Fragment>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Data</Italian>
                            <English>Date</English>
                            <Russian>Дата</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"date"}
                            placeholder={"GG/MM/AAAA"}
                            min={minDate}
                            value={departureDate}
                            onChange={e => setDepartureDate(e.target.value)}
                        />
                    </div>
                </label>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Ora</Italian>
                            <English>Hour</English>
                            <Russian>Время</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"time"}
                            placeholder={"HH:MM"}
                            min={minDate}
                            value={departureTime}
                            onChange={e => setDepartureTime(e.target.value)}
                        />
                    </div>
                </label>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Indirizzo di partenza</Italian>
                            <English>Departure address</English>
                            <Russian>Адрес отправления</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"text"}
                            placeholder={"Castelfranco Emilia, Aeroporto di Bologna, ..."}
                            value={departure}
                            onChange={e => setDeparture(e.target.value)}
                        />
                    </div>
                </label>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Indirizzo di arrivo</Italian>
                            <English>Arrival address</English>
                            <Russian>Адрес назначения</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"text"}
                            placeholder={"Stazione FS di Modena, Policlinico, ..."}
                            value={arrival}
                            onChange={e => setArrival(e.target.value)}
                        />
                    </div>
                </label>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Andata e ritorno</Italian>
                            <English>Round trip</English>
                            <Russian>Поездка в оба конца</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <select
                            value={twoways}
                            onChange={e => setTwoways(e.target.value)}
                        >
                            <option value={"false"}>
                                {{
                                    "it": "❌ No",
                                    "en": "❌ No",
                                    "ru": "❌ Нет",
                                }[language]}
                            </option>
                            <option value={"true"}>
                                {{
                                    "it": "✅ Sì",
                                    "en": "✅ Yes",
                                    "ru": "✅ Да",
                                }[language]}
                            </option>
                        </select>
                    </div>
                </label>
                {twoways === "true" ? <Fragment>
                    <label className={Style.Label}>
                        <div className={Style.Text}>
                            <LanguageGroup>
                                <Italian>Data del ritorno prevista</Italian>
                                <English>Expected date of return</English>
                                <Russian>Ожидаемая дата возвращения</Russian>
                            </LanguageGroup>
                        </div>
                        <div className={Style.Input}>
                            <input
                                type={"date"}
                                placeholder={"GG/MM/AAAA"}
                                min={minDate}
                                value={arrivalDate}
                                onChange={e => setArrivalDate(e.target.value)}
                            />
                        </div>
                    </label>
                    <label className={Style.Label}>
                        <div className={Style.Text}>
                            <LanguageGroup>
                                <Italian>Ora del ritorno prevista</Italian>
                                <English>Estimate time of return</English>
                                <Russian>Время возвращения</Russian>
                            </LanguageGroup>
                        </div>
                        <div className={Style.Input}>
                            <input
                                type={"time"}
                                placeholder={"HH:MM"}
                                min={minDate}
                                value={arrivalTime}
                                onChange={e => setArrivalTime(e.target.value)}
                            />
                        </div>
                    </label>
                </Fragment> : null}
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Numero adulti</Italian>
                            <English>Adults</English>
                            <Russian>Количество взрослых</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"number"}
                            min={1}
                            max={MAX_PEOPLE}
                            value={adults}
                            onChange={e => setAdults(e.target.value)}
                        />
                    </div>
                </label>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Numero bambini</Italian>
                            <English>Children</English>
                            <Russian>Количество детей</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"number"}
                            min={0}
                            max={MAX_PEOPLE - adults}
                            value={children}
                            onChange={e => setChildren(e.target.value)}
                        />
                    </div>
                </label>
                {
                    children > 0 &&
                    <label className={Style.Label}>
                        <div className={Style.Text}>
                            <LanguageGroup>
                                <Italian>Età bambini</Italian>
                                <English>Children age</English>
                                <Russian>Возраст ребёнка</Russian>
                            </LanguageGroup>
                        </div>
                        <div className={Style.Input}>
                            <input
                                type={"text"}
                                value={childrenAge}
                                onChange={e => setChildrenAge(e.target.value)}
                            />
                        </div>
                    </label>
                }
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Seggiolini richiesti</Italian>
                            <English>Child seats required</English>
                            <Russian>Требуются детские сиденья</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <input
                            type={"number"}
                            min={0}
                            max={children}
                            value={chairs}
                            onChange={e => setChairs(e.target.value)}
                        />
                    </div>
                </label>
                <label className={Style.Label}>
                    <div className={Style.Text}>
                        <LanguageGroup>
                            <Italian>Valigie</Italian>
                            <English>Luggage</English>
                            <Russian>Чемоданы</Russian>
                        </LanguageGroup>
                    </div>
                    <div className={Style.Input}>
                        <select
                            value={luggage}
                            onChange={e => setLuggage(e.target.value)}
                        >
                            <option value={"Nessuna"}>
                                {{
                                    "it": "Nessuna",
                                    "en": "None",
                                    "ru": "Нет",
                                }[language]}
                            </option>
                            <option value={"Piccole"}>
                                {{
                                    "it": "Piccole",
                                    "en": "Small",
                                    "ru": "Маленький",
                                }[language]}
                            </option>
                            <option value={"Medie"}>
                                {{
                                    "it": "Medie",
                                    "en": "Medium",
                                    "ru": "Средний",
                                }[language]}
                            </option>
                            <option value={"Grandi"}>
                                {{
                                    "it": "Grandi",
                                    "en": "Large",
                                    "ru": "Большой",
                                }[language]}
                            </option>
                        </select>
                    </div>
                </label>
            </Fragment> : null}
            <label className={classNames(Style.Label, Style.Notes)}>
                <div className={Style.Text}>
                    <LanguageGroup>
                        <Italian>Note</Italian>
                        <English>Notes</English>
                        <Russian>Дополнительные заметки</Russian>
                    </LanguageGroup>
                </div>
                <div className={Style.Input}>
                    <textarea
                        onChange={e => setNotes(e.target.value)}
                        value={notes}
                    />
                </div>
            </label>
            <div>
                <a className={classNames(Style.Button, "button")} href={mailtoLink}>
                    <FontAwesomeIcon icon={faEnvelope}/>
                    &nbsp;
                    <LanguageGroup>
                        <Italian>Invia richiesta</Italian>
                        <English>Send the request</English>
                        <Russian>Отправить запрос</Russian>
                    </LanguageGroup>
                </a>
            </div>
        </form>
    )
}
