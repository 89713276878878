import React from "react"
import Style from "./ButtonsContainer.module.css"
import classNames from "classnames"


export default function ButtonsContainer({children, className, ...props}) {
    return (
        <div className={classNames(Style.ButtonsContainer, className)} {...props}>
            {children}
        </div>
    )
}
