import React from "react"
import Style from "./Box.module.css"
import classNames from "classnames"


export default function Box({children, className}) {
    return (
        <section className={classNames(Style.Box, className)}>
            {children}
        </section>
    )
}
