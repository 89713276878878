import React from "react"
import Style from "./HLimiter.module.css"
import classNames from "classnames"


export default function HLimiter({children, className, width, ...props}) {
    return (
        <div
            style={{
                "flexBasis": width,
            }}
            className={classNames(Style.HLimiter, className)}
            {...props}
        >
            {children}
        </div>
    )
}
