import React, {Fragment, useContext} from "react";
import ContextLanguage from "../../contexts/ContextLanguage";


export default function Language({children, code, invert}) {
    const currentLanguage = useContext(ContextLanguage);

    if(currentLanguage === undefined) {
        throw new Error("'REACT_APP_LANGUAGE' environment variable is not set")
    }

    if((currentLanguage === code) ^ !invert) {
        return null;
    }

    return (
        <Fragment>
            {children}
        </Fragment>
    )
}
