import React, {useEffect, useState} from "react"
import Style from "./Navbar.module.css"
import classNames from "classnames"
import Logo from "../media/Logo.svg"
import Italian from "./i18n/lang/Italian"
import English from "./i18n/lang/English"
import Russian from "./i18n/lang/Russian"
import Icon from "./Icon"
import Box from "./Box"
import Twemoji from "./Twemoji"


export default function Navbar({children, className, setLanguage}) {
    const [isCollapsed, setCollapsed] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
            setCollapsed(document.documentElement.scrollTop > 1)
        }
    }, [])

    return (
        <nav id={"navbar"} className={classNames(Style.Navbar, isCollapsed ? Style.Collapsed : null, className)}>
            <div className={Style.Logo}>
                <a href={"#"} tabIndex={0}>
                    <img src={Logo} alt={"AB TAXI NCC - Alessandro"}/>
                </a>
            </div>
            <div className={Style.MainTitle}>
                AB <b>TAXI</b> NCC
            </div>
            <div className={Style.Icons}>
                <Italian invert={true}>
                    <a onClick={(_) => setLanguage("it")} tabIndex={0}>
                        <Icon ariaLabel={"Lingua: Italiano"}>
                            <Twemoji emoji={"🇮🇹"}/>
                        </Icon>
                    </a>
                </Italian>
                <English invert={true}>
                    <a onClick={(_) => setLanguage("en")} tabIndex={0}>
                        <Icon ariaLabel={"Language: English"}>
                            <Twemoji emoji={"🇬🇧"}/>
                        </Icon>
                    </a>
                </English>
                <Russian invert={true}>
                    <a onClick={(_) => setLanguage("ru")} tabIndex={0}>
                        <Icon ariaLabel={"Язык: Русский"}>
                            <Twemoji emoji={"🇷🇺"}/>
                        </Icon>
                    </a>
                </Russian>
            </div>
        </nav>
    )
}
