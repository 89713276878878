import React, {Fragment} from "react";


export default function LanguageGroup({children}) {
    return (
        <Fragment>
            {children}
        </Fragment>
    )
}
