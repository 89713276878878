import React from "react";
import Style from "./Footer.module.css";
import classNames from "classnames";


export default function Footer({children, className}) {
    return (
        <footer id={"footer"} className={classNames(Style.Footer, className)}>
            {children}
        </footer>
    )
}
