import React from "react"
import Style from "./IconContainer.module.css"
import classNames from "classnames"
import Icon from "./Icon"


export default function IconContainer({icon, title, children, className, clickable}) {
    return (
        <div className={classNames(Style.Container, className)}>
            {icon ?
                <Icon className={Style.Icon}>
                    {icon}
                </Icon>
            : null}
            {title ?
                <h2 className={Style.Title}>
                    {title}
                </h2>
            : null}
            {children ?
                <div className={Style.Children} style={{"alignSelf": title ? "start" : "center"}}>
                    {children}
                </div>
            : null}
        </div>
    )
}
