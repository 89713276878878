import React from "react"
import Style from "./Icon.module.css"
import classNames from "classnames"


export default function Icon({children, className, contentsClassName, containerClassName}) {
    return (
        <div className={classNames(Style.Icon, className)}>
            <div className={classNames(Style.Container, containerClassName)}>
                <div className={classNames(Style.Contents, contentsClassName)}>
                    {children}
                </div>
            </div>
        </div>
    )
}
