import React from "react"
import Style from "./Clean.module.css"
import classNames from "classnames"


export default function Clean({children, className}) {
    return (
        <div className={classNames(Style.Clean, className)}>
            {children}
        </div>
    )
}
