import React from "react"
import Style from "./HFill.module.css"
import classNames from "classnames";


export default function HFill({
    id,
    children,
    className,
    containerClassName,
    anchorClassName,
    backgroundColor,
    backgroundPositionX,
    backgroundPositionY,
    backgroundRepeat,
    backgroundAttachment,
    backgroundImage,
    backgroundSize,
    backgroundOrigin,
    backgroundClip,
    height,
    containerProps,
    overlayPct,
    ...props
}) {
    console.log(backgroundSize)

    let style = {
        "backgroundColor": backgroundColor ?? "var(--background-color)",
        "backgroundPositionX": backgroundPositionX,
        "backgroundPositionY": backgroundPositionY,
        "backgroundRepeat": backgroundRepeat,
        "backgroundAttachment": backgroundAttachment,
        "backgroundImage": backgroundImage ? `url(${backgroundImage})` : "none",
        "backgroundSize": backgroundSize,
        "backgroundOrigin": backgroundOrigin,
        "backgroundClip": backgroundClip,
    }

    if(containerProps && containerProps["style"]) {
        style = {...style, ...containerProps["style"]}
    }

    return (
        <div
            style={style}
            className={classNames(Style.HFillContainer, containerClassName)}
            {...containerProps}
        >
            <a id={id} name={id} className={classNames(Style.Anchor, anchorClassName)}/>
            <div
                style={{
                    "backgroundColor": `rgba(var(--background-part), ${overlayPct ?? 0.0})`,
                    "minHeight": height,
                }}
                className={classNames(Style.HFill, className)}
                {...props}
            >
                {children}
            </div>
        </div>
    )
}
