import React from "react";
import HFill from "./HFill"
import HLimiter from "./HLimiter"


export default function Jumbox({
    children,
    width,
    hlimiterProps,
    ...hfillProps
}) {
    return (
        <HFill
            {...hfillProps}
        >
            <HLimiter width={width ?? "720px"} {...hlimiterProps}>
                {children}
            </HLimiter>
        </HFill>
    )
}
