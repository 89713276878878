import React from "react"
import Style from "./Mosaic.module.css"
import classNames from "classnames"


export default function Mosaic({elements, className, tileClassName, size}) {

    const tiles = elements.map((element, pos) => (
        <a href={element.href} target={"_blank"} className={classNames(Style.Tile, element.href ? Style.Clickable : null, tileClassName)} key={pos}
            style={{
                "--mosaic-size": size ?? "256px"
            }} aria-label={element.label ?? element.caption} rel={element.isolate ? "noopener" : ""}
        >
            <img className={Style.Image} src={element.src} alt={""} style={{
                "objectFit": element.fit ?? "cover",
            }}/>
            {element.caption ?
                <div className={Style.Caption}>{element.caption}</div>
            : null}
        </a>
    ))

    return (
        <div className={classNames(Style.Mosaic, className)}>
            {tiles}
        </div>
    )
}
